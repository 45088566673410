import { Menu } from "./ui-components";
import "./App.css";

function App() {
  const sign = `
|￣￣￣￣￣￣￣￣￣￣￣|
  if he wanted to
    he would.
|＿＿＿＿＿＿＿＿＿＿＿|
(\\_/) ||
(•ㅅ•) ||
/  　づ
`;
  console.log(sign);
  return <Menu style={{backgroundColor: "red"}}></Menu>;
}

export default App;
