import { Button, Card, Flex, Heading, Image } from "@aws-amplify/ui-react";

import { fairyDustCursor } from "cursor-effects";
import cursor from "../wand.png";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Home({ background }) {
  useEffect(() => {
    document.body.style.background = background;

    // Clean up the effect when the component is unmounted
    return () => {
      document.body.style.background = "white";
    };
  }, [background]);
  const navigate = useNavigate();
  const [partyMode, setPartyMode] = React.useState(false);
  const [fairyDust, setFairyDust] = React.useState("");
  const handlePartyMode = (e) => {
    const newVal = e.target.checked;
    if (newVal) {
      console.log("Starting party mode");
      setFairyDust(
        new fairyDustCursor({ colors: ["#FFE033", "#F7E57F", "#F9D064"] })
      );
      document.body.style.cursor = `url(${cursor}), auto`;
    } else {
      fairyDust.destroy();
      document.body.style.cursor = "auto";
    }
    setPartyMode(newVal);
  };
  return (
    <>
      <Card
        // columnStart="1"
        // columnEnd="-1"
        column="auto / span 6"
        row={{ medium: "3", base: "3" }}
        style={{
          padding: "0",
          backgroundColor: "rgba(0,0,0,0.0)",
        }}
      >
        <Heading
          level={1}
          style={{
            color: "black",
            whiteSpace: "nowrap",
            textAlign: "center",
          }}
          fontSize={{ medium: "5vw", base: "6vw" }}
        >
          DIANA & NICKI
        </Heading>
        <Heading
          level={2}
          style={{
            color: "black",
            whiteSpace: "nowrap",
            textAlign: "center",
          }}
          fontSize={{ medium: "3.5vw", base: "5vw" }}
        >
          September 7, 2024
        </Heading>
        <Flex margin="2em" alignItems="center" justifyContent="center">
          <Button fontSize={{medium: "2vw", base: "3vw"}} onClick={() => navigate("/rsvp")}>
            RSVP
          </Button>
        </Flex>
      </Card>
    </>
  );
}
