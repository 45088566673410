import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  TextField,
  Text,
} from "@aws-amplify/ui-react";
import axios from "axios";
import * as React from "react";

export default function MyAuthenticator(props) {
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password) {
      setError("A password is required.");
      return;
    }
    try {
      const resp = await axios.post(
        "https://g2yt32tc4wj5sgn5ub4ebiut3e0dvspg.lambda-url.us-east-1.on.aws/",
        { key: `${password}` }
      );
      if (resp.status === 200) {
        props.setToken(resp.data.token);
      }
    } catch (error) {
      if (error.response.status === 403) {
        setError("Incorrect password.");
      }
    }
  };
  return (
    <Grid
      as="form"
      padding="20px"
      onSubmit={handleSubmit}
      columnGap="0.5rem"
      rowGap="0.5rem"
      templateColumns={{ medium: "50%", base: "100%" }}
      templateRows="100%"
      justifyContent="center"
    >
      <Card column="1" variation="outlined">
        <Flex justifyContent="center">
          <TextField
            label={
              <Text
                as="p"
                style={{
                  "textAlign": "center",
                  "fontSize": "1.5em",
                }}
              >
                Enter the Website Password
              </Text>
            }
            type="text"
            name="password"
            onChange={(e) => {
              let newVal = e.target.value;
              if (!newVal) {
                setError("");
              }
              setPassword(newVal);
            }}
            value={password}
            isRequired={false}
            isReadOnly={false}
            errorMessage={error}
            hasError={error}
            descriptiveText={
                "If you don't know the password, contact a bride."
            }
          ></TextField>
        </Flex>
        <Flex justifyContent="center" style={{ padding: "12px" }}>
          <Button type="submit" isDisabled={!password}>
            Submit
          </Button>
        </Flex>
      </Card>
    </Grid>
  );
}
