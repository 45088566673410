import { Card, Heading, Image, View } from "@aws-amplify/ui-react";
import detail1 from "./detail1.jpg";
import detail2 from "./details.jpg";
import React, { useEffect } from "react";


export default function Details({ background }) {
  useEffect(() => {
    document.body.style.background = background;

    // Clean up the effect when the component is unmounted
    return () => {
      document.body.style.background = "white";
    };
  }, [background]);
  return (
    <>
    <Card column="auto / span 6" row="2" paddingBottom="8px">
        <View style={{ textAlign: "center" }}>
          <Heading level={2} fontSize={{ medium: "4vw", base: "6vw" }}>
            Details
          </Heading>
        </View>
      </Card>
      <Card row={{ medium: "3 / span 6", base: "3" }}
        column={{ medium: "2 / 4", base: "2 / span 4" }}>
        <Image objectFit="initial" src={detail2}/>
      </Card>
      {/* <Card
        column={{ medium: "3 / 5", base: "1" }}
        // columnStart="1"
        row="3"
        backgroundColor="rgba(0,0,0,0.0)"
      >
        <View style={{textAlign: "right" }}>
        <Heading level={2}>06<br/>September<br/>2024</Heading>
        </View>
      </Card>
      <Card
        column={{ medium: "3 / 5", base: "1" }}
        // columnStart="1"
        row="4"
        backgroundColor="rgba(0,0,0,0.0)"
      >
        <View style={{textAlign: "right" }}>
        <Heading level={2}>07<br/>September<br/>2024</Heading>
        </View>
      </Card> */}
      <Card column={{medium: "auto / span 2", base: "1 / span 6"}} row={{medium: "5", base: "6"}} id="accommodations">
        <Heading level={3} fontSize={{ medium: "2vw", base: "6vw" }}>
          Accommodations
        <Heading level={3} fontSize={{ medium: "1.5vw", base: "5vw" }}>Hyatt Regency Westlake Room Block</Heading>
        <p>This room block is no longer taking reservations.</p>
        <p>Check-in begins at 4:00pm on Friday and check-out is at 11:00am on Sunday.
        We will have shuttles to/from the venue from this hotel, but you don't need to stay here to take the shuttle.</p>
        <Heading level={3} fontSize={{ medium: "1.5vw", base: "5vw" }}>Marriott Residence Inn Westlake Village Room Block</Heading>
        <p>This room block is no longer taking reservations.</p>
        <p>Check-in begins at 3:00pm on Friday and check-out is at 12:00pm on Sunday. Breakfast is included. We will have shuttles to/from the venue from this hotel, but you don't need to stay here to take the shuttle.</p>
         <p>Other recommended hotels include:</p>
         <ul style={{"fontSize": "1rem", "fontWeight": "normal"}}>
            <li><a target="_blank" className="address" href="https://www.sonesta.com/sonesta-select/ca/camarillo/sonesta-select-camarillo">Sonesta Select Camarillo</a> - 3-star hotel, 10 minute drive from our venue</li>
            <li><a target="_blank" className="address" href="https://www.marriott.com/en-us/hotels/oxrcr-residence-inn-camarillo/overview/">Residence Inn Camarillo</a> - 3-star hotel, 10 minute drive from our venue</li>
            <li><a target="_blank" className="address" href="https://www.westlakevillageinn.com/">Westlake Village Inn</a> - 4-star hotel, 3 minute drive from our room block</li>
          </ul>
        </Heading>
        <Heading level={3} fontSize={{ medium: "2vw", base: "6vw" }}>
          Travel
        <p>Burbank and LAX are the nearest airports (Burbank is smaller but closer). Public transportation in the area from the airports is limited, but there is a Metrolink station in Camarillo that connects to both Metrolink and Amtrak. We also recommend renting a car or taking rideshare or a taxi.<br/>
        We will have shuttles to/from the wedding venue leaving from our room blocks at the Hyatt Regency Westlake and Marriott Residence Inn, but you don't need to stay here to take the shuttle. Rideshares are also available throughout the area. Free parking is available at both the Welcome Party and Wedding Ceremony.</p>
        </Heading>
      </Card>
      <Card row={{medium: "3", base: "4"}} column={{medium: "auto / span 2", base: "1 / span 6"}} backgroundColor="rgba(0,0,0,0.0)" id="welcomeparty">
        <View>
        <Heading level={3} fontSize={{ medium: "2vw", base: "6vw" }}>
        Welcome Party<br/>
          6:30 PM <br/>
          September 6, 2024<br/>
          <p style={{whiteSpace: "nowrap"}}>
          Los Robles Greens<br/>
          <a target="_blank" className="address" href="https://maps.app.goo.gl/jaECk89Z1ttDwrCH7">
          299 S Moorpark Rd<br/>
          Thousand Oaks, CA 91361<br/>
          </a>
        </p>
        </Heading>
        <p>Join us the night before our big day for a welcome celebration with dinner and drinks. Parking is free. The dress code is semi-formal and <strong>white.</strong> Yes, you read that right! We want to share
        our spotlight with everyone for the night. Outfits do not need to be fully white. For example, a white shirt and khaki pants or white dress with print would be perfectly fine.</p><p>
        The Welcome Party kicks off with appetizers and drinks at 6:30pm. As part of Afghan tradition, we’ll have a henna artist available to do henna tattoos for all our guests who are interested. Afghan henna usually consists of a single dot on the inside of the palm, but guests are welcome to request more elaborate designs if they choose. A buffet dinner is available from 7:30-8:30pm. We know many of you are getting in on this day, so even if you can’t make it right at 6:30, you’re welcome to join whenever you can for dinner.</p>
        </View>
      </Card>
      <Card row={{medium: "4", base: "5"}} column={{medium: "auto / span 2", base: "1 / span 6"}} backgroundColor="rgba(0,0,0,0.0)">
        <View>
        <Heading level={3} fontSize={{ medium: "2vw", base: "6vw" }}>
          Wedding Ceremony<br/>
          4:30 PM<br/>
          September 7, 2024 <br/>
          <p style={{whiteSpace: "nowrap"}}>
          Maravilla Gardens<br/>
          <a target="_blank" className="address" href="https://maps.app.goo.gl/kzcXNx1hAj4EQLih8">
          8620 Santa Rosa Road<br/>
          Camarillo, CA 93012<br/>
          </a>
        </p>
        </Heading>
          <p>The day we've all been waiting for! The dress code is formal. We ask that guests wear a tuxedo or suit, mid-length or floor-length dress, or pantsuit. Please avoid wearing white.</p>
          <p>Shuttles will be available to/from Maravilla departing from the Hyatt Regency Westlake. Guests who aren't staying at the Hyatt can still take the shuttle. The shuttle will depart at <strong>3:30pm. </strong>
            Alternatively, rideshare (Uber/Lyft) operate in the area. Free parking is also available at the venue. <strong>Please arrive at Maravilla no later than 4:15pm</strong> (no Berkeley time).</p>
        <Heading level={3} fontSize={{ medium: "1.5vw", base: "4vw" }} style={{marginBottom:"2px"}}>
          Cocktail Hour and Reception to follow at Maravilla Gardens.<br/>
        </Heading>
        </View>
      </Card>
    </>
  );
}
