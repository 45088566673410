import "@aws-amplify/ui-react/styles.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import { studioTheme } from "./ui-components";

const myTheme = createTheme(
  {
    name: "my-theme",
    tokens: {
      components: {
        badge: {
          success: {
            color: { value: "#4c5949" },
            // backgroundColor: { value: "#798476" },
          },
        },
        button: {
          primary: {
            backgroundColor: {
              value: "#ADD8E6",
            },
          },
        },
        heading: {
          1: {
            fontSize: { value: "5vw" },
          },
          2: {
            fontSize: { value: "5vw" },
            fontFamily: { value: "Gilda Display" },
          },
          3: {
            fontSize: { value: "2vw" }
          }
        },
        menu: {
          position: "fixed",
          top: "0",
        },
        link: {
          focus: {
            color: { value: "#4c5949" },
            textDecoration: { value: "underline" }
          },
          hover: {
            color: { value: "#848D88" },
            textDecoration: { value: "underline" }
          },
          visited: {
            color: { value: "#4c5949" },
            textDecoration: { value: "underline" }
          },
          active: {
            color: { value: "#4c5949" },
            textDecoration: { value: "underline" }
          },
          color: { value: "#4c5949" },
          textDecoration: { value: "underline" }
        },
        checkbox: {
          button: {
            color: { value: "#848D88" },
            _focus: {
              outlineColor: { value: "#848D88" },
              borderColor: { value: "#848D88" },
            },
          },
          icon: {
            backgroundColor: { value: "#4c5949" },
          },
          label: {
            color: { value: "#848D88" },
            _disabled: {
              color: { value: "#848D88" },
            },
          },
        },
        radiogroup: {
          radio: {
            borderWidth: { value: '{borderWidths.small}' },
            borderColor: { value: "#848D88" },
            _checked: {
              color: { value: "#4c5949" },
            },
            label: {
              color: { value: "#4c5949" },
            },
          },
          legend: {
            color: { value: "#4c5949" },
            fontWeight: { value: '{fontWeights.bold}' },
          },
        },
      },
      fonts: {
        default: {
          variable: { value: 'Gilda Display'}
        }
      },
      text: {
        secondary: {
          fontSize: { value: '0.8rem' },
        },
      }
    },
  },
  studioTheme
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={myTheme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
