import {
  Button,
  Card,
  CheckboxField,
  Flex,
  Grid,
  Heading,
  Message,
  TextField,
  Text,
  View,
  PhoneNumberField,
} from "@aws-amplify/ui-react";
import axios from "axios";
import React, { useEffect } from "react";
import { getGuest, guestsByPartyID } from "../graphqlaws/queries";
import { Oval } from 'react-loader-spinner';
import {
  createParty,
  createGuest,
  createMeal,
  updateGuest,
} from "../graphqlaws/mutations";
import { Amplify } from "aws-amplify";
import config from "../graphql/config.js";
import { generateClient } from "@aws-amplify/api";
import GuestRSVP from "./GuestRSVP.jsx";

Amplify.configure(config);

export default function RSVPLookup({ background }) {
  useEffect(() => {
    document.body.style.background = background;

    // Clean up the effect when the component is unmounted
    return () => {
      document.body.style.background = "white";
    };
  }, [background]);
  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [dialcode, setDialCode] = React.useState("+1");

  const [error, setError] = React.useState("");
  const [submitError, setSubmitError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [guests, setGuests] = React.useState("");

  const capitalizeFirstLetter = (word) => {
    if (!word) return ''; // Return an empty string if the input is not valid
    if (word.toLowerCase() === 'de guilhem de lataillade') {
      return 'de Guilhem de Lataillade';
    }
    if (word.toLowerCase() === 'mcafee') {
      return 'McAfee';
    }
    if (word.toLowerCase() === 'de la rosa') {
      return 'de la Rosa';
    }
    // Check if the first character is lowercase
    if (word[0] === word[0].toLowerCase()) {
        // Capitalize the first character and concatenate it with the rest of the string
        return word[0].toUpperCase() + word.slice(1);
    }
    
    // Return the word unchanged if the first character is already uppercase
    return word;
}

  const lookupGuest = async (event) => {
    setError("");
    event.preventDefault();
    if (!firstname || !lastname) {
      setError("First and last name is required to lookup your invitation.");
      return;
    }
    try {
      const client = generateClient();
      const results = await client.graphql({
        query: getGuest,
        variables: {
            lastname: capitalizeFirstLetter(lastname),
            firstname: capitalizeFirstLetter(firstname)
        },
      });
      if (!results.data.getGuest) {
        throw new Error("No guest found.");
      }
      const partyId = results.data.getGuest.partyID;
      const guestResults = await client.graphql({
        query: guestsByPartyID,
        variables: {
          partyID: partyId
        }
      });
      const guestsInParty = guestResults.data.guestsByPartyID.items;
      if (guestsInParty.length !== 0) {
        const hostIdToPlusOne = {};
        const guestList = guestsInParty.reduce((acc, guest) => {
          if (guest.hostID) {
            // a plus-one
            hostIdToPlusOne[guest.hostID] = guest;
          } else {
            acc[guest.id] = guest;
          }
          return acc;
        }, {});
        for (const [hostId, plusOne] of Object.entries(hostIdToPlusOne)) {
          guestList[hostId].plusone = plusOne;
        }
        setGuests(guestList);
      } else {
        throw new Error("No guests in party found.");
      }
    } catch (error) {
      if (error) {
        console.error(error);
        setError(
          "Sorry, we're having trouble finding your invite! Enter your details exactly as they appear on your invitation or contact Nicki for further assistance at (408) 966-0464"
        );
      }
    }
  };

  const setGuest = (guest) => {
    const guestID = guest.id;
    const newGuests = { ...guests, [guestID] : guest };
    setGuests(newGuests);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    if (submitError) {
      setSubmitError(false);
    }
    event.preventDefault();
    try {
      const resp = await axios.post(
        "https://k4vm2zfumxnpxnjab2z7bo4dxu0tvihp.lambda-url.us-east-1.on.aws/",
        { guestsIn: guests }
      );
      if (resp.status === 201) {
        setSubmitError(false);
        setSubmitted(true);
      }
    } catch (error) {
      setSubmitError(true);
    }
    setLoading(false);
  };

  if (guests) {
    if (submitted) {
      const anyAttending = Object.values(guests).some(guest => guest.attending);
      return (
        <Card
          column={{medium: "3 / span 2", base: "2 / span 4"}}
          row="3"
        >
          <Message
            variation="outlined"
            colorTheme="success"
            heading="RSVP submitted!">
            {anyAttending && "You'll receive a confirmation email soon."}
          </Message>
        </Card>
      );
    } else if (loading) {
      return (
        <Flex
        direction="column"
        column={{medium: "3 / span 2", base: "2 / span 4"}}
        row="3"
        alignItems="center"
        >
          <Oval color="#434f48" secondaryColor="#848d88" height={40} width={40} />
        </Flex>
      )
    } else {
      return (
        <Flex as="form" onSubmit={handleSubmit} 
        justifyContent="center" direction="column" alignItems="center"
        alignContent="center" gap="1rem"
        column={{medium: "auto / span 6", base: "2 / span 4"}}
        row="3">
          {submitError && <Message
            variation="outlined"
            colorTheme="error"
            heading="Couldn't submit your RSVP!">
            Probably not your fault, sorry :-) Contact Nicki for more help at (408) 966-0464
          </Message>}
          {Object.entries(guests).map(([guestID, guest]) => {
            return <GuestRSVP guest={guest} setGuest={setGuest} key={guestID} completed={guest.rsvped}/>;
          })}
          <Flex justifyContent="center">
            <Button
              type="submit"
              isDisabled={Object.values(guests).some(guest => !guest.rsvped)}
              style={{ maxHeight: "40px", "marginRight": "1em", "marginTop": "1em" }}
              >
                Submit
            </Button>
          </Flex>
        </Flex>
      );
    }
  } else {
    return (
      <Card
        as="form"
        column={{medium: "3 / span 2", base: "2 / span 4"}}
        row="3"
        variation="outlined"
        onSubmit={lookupGuest}
      >
        <Flex justifyContent="center" direction="column">
          <TextField
            label={
              <Text
                as="p"
                style={{
                  // textAlign: "center",
                  fontSize: "1.5em",
                }}
              >
                First Name
              </Text>
            }
            type="text"
            name="firstname"
            onChange={(e) => {
              let newVal = e.target.value;
              if (!newVal) {
                setError("");
              }
              setFirstName(newVal);
            }}
            value={firstname}
            isRequired={false}
            isReadOnly={false}
            hasError={error}
            // descriptiveText={"Lookup "}
          ></TextField>
          <TextField
            label={
              <Text
                as="p"
                style={{
                  // textAlign: "center",
                  fontSize: "1.5em",
                }}
              >
                Last Name
              </Text>
            }
            type="text"
            name="lastname"
            onChange={(e) => {
              let newVal = e.target.value;
              if (!newVal) {
                setError("");
              }
              setLastName(newVal);
            }}
            value={lastname}
            isRequired={false}
            isReadOnly={false}
            errorMessage={error}
            hasError={error}
          ></TextField>
        </Flex>
        <Flex justifyContent="center" style={{ padding: "12px" }}>
          <Button type="submit" isDisabled={!firstname || !lastname}>
            Lookup Invite
          </Button>
        </Flex>
      </Card>
    );
  }
}
