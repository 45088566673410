import { Details, Authenticator, FAQ, Home, RSVPLookup, Story } from ".";
import {
  Link,
  Card,
  Flex,
  Grid,
  Image,
  Menu as AmplifyMenu,
  MenuItem,
  View,
} from "@aws-amplify/ui-react";
import { NavLink as ReactRouterLink, Routes, Route } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as React from "react";
import background from "../img/home_mobile.jpg";
import icon from "../img/icon.png";
const backgroundImage = `url(${background}) center/cover no-repeat`;

const useToken = (password) => {
  const getToken = (password) => {
    if (password == "2024") {
      return password;
    }
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = React.useState(getToken(password));

  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    token,
    setToken: saveToken,
  };
};

export default function Menu(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { token, setToken } = useToken(searchParams.get("password"));

  if (!token) {
    return <Authenticator setToken={setToken}></Authenticator>;
  } else {
    return (
      <Grid
        // columnGap={{ medium: "0.5rem", base: "0rem" }}
        // rowGap="0.5rem"
        templateColumns="repeat(6, 1fr)"
        templateRows="60px repeat(6, 1fr)"
        maxHeight="100vh"
      >
        {/* <Card row="1" column="1" style={{"background": "transparent"}}>

        </Card> */}
        <Flex
          row="1"
          backgroundColor="rgba(0,0,0,0.0)"
          column={{ medium: "1 / 4", base: "1 / 6" }}
          alignItems="center"
          className="webMenuView"
          style={{ zIndex: "1" }}
          paddingLeft="16px"
          paddingTop="16px"
        >
          <Image
            src={icon}
            height="100%"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          />
          <ReactRouterLink to="/" className="webMenu">
            HOME
          </ReactRouterLink>
          <ReactRouterLink to="/faq" className="webMenu">
            FAQ
          </ReactRouterLink>
          <ReactRouterLink to="/ourstory" component={Link} className="webMenu">
            OUR STORY
          </ReactRouterLink>
          <ReactRouterLink to="/details" component={Link} className="webMenu">
            DETAILS
          </ReactRouterLink>
          <ReactRouterLink to="/rsvp" component={Link} className="webMenu">
            RSVP
          </ReactRouterLink>
        </Flex>
        <View
          column="6"
          row="1"
          justifyContent="right"
          paddingRight="16px"
          paddingTop="16px"
        >
          <AmplifyMenu
            menuAlign="end"
            size="large"
            className="mobile-menu"
            triggerClassName="mobile-menu-trigger"
            backgroundColor="rgba(0,0,0,0.0)"
          >
            <MenuItem
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/faq");
              }}
            >
              FAQ
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/ourstory");
              }}
            >
              Our Story
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/details");
              }}
            >
              Details
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/rsvp");
              }}
            >
              RSVP
            </MenuItem>
          </AmplifyMenu>
        </View>
        <Routes>
          <Route path="ourstory" element={<Story background="none" />} />
          <Route path="rsvp" element={<RSVPLookup background="none" />} />
          <Route path="faq" element={<FAQ background="none" />} />
          <Route path="details" element={<Details background="none"/>} />
          <Route path="/" element={<Home background={backgroundImage} />} />
        </Routes>
      </Grid>
    );
  }
}
