/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getParty = /* GraphQL */ `
  query GetParty($id: ID!) {
    getParty(id: $id) {
      id
      Name
      PartyGuests {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listParties = /* GraphQL */ `
  query ListParties(
    $filter: ModelPartyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeal = /* GraphQL */ `
  query GetMeal($id: ID!) {
    getMeal(id: $id) {
      id
      Type
      GuestMeal {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeals = /* GraphQL */ `
  query ListMeals(
    $filter: ModelMealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGuest = /* GraphQL */ `
  query GetGuest(
    $firstname: String!
    $lastname: String!) {
    getGuest(firstname: $firstname, lastname: $lastname) {
      id
      firstname
      lastname
      phonenumber
      email
      vegan
      vegetarian
      glutenfree
      nutfree
      otherallergy
      shuttle
      roomblock
      bookedroomblock
      welcomeparty
      attending
      plusoneallowed
      partyID
      hostID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGuests = /* GraphQL */ `
  query ListGuests(
    $filter: ModelGuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        phonenumber
        email
        vegan
        vegetarian
        glutenfree
        nutfree
        otherallergy
        shuttle
        roomblock
        bookedroomblock
        welcomeparty
        attending
        plusoneallowed
        partyID
        hostID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartyAndGuests = /* GraphQL */ `
  query GetPartyAndGuests(
    $firstname: String!
    $lastname: String!
  ) {
    getPartyAndGuests(firstname: $firstname, lastname: $lastname) {
      id
      firstname
      lastname
      phonenumber
      email
      vegan
      vegetarian
      glutenfree
      nutfree
      otherallergy
      shuttle
      roomblock
      bookedroomblock
      welcomeparty
      attending
      plusoneallowed
      partyID
      createdAt
      updatedAt
      __typename
      party {
        id
        name
        guests {
          id
          firstname
          lastname
        }
      }
    }
  }
`;
export const guestsByMealID = /* GraphQL */ `
  query GuestsByMealID(
    $mealID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    guestsByMealID(
      mealID: $mealID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        phonenumber
        email
        mealID
        partyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const guestsByPartyID = /* GraphQL */ `
  query GuestsByPartyID(
    $partyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    guestsByPartyID(
      partyID: $partyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        phonenumber
        email
        vegan
        vegetarian
        glutenfree
        nutfree
        otherallergy
        shuttle
        roomblock
        bookedroomblock
        welcomeparty
        attending
        plusoneallowed
        partyID
        hostID
        dialcode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
