import {
    Badge,
    Button,
    Card,
    CheckboxField,
    Flex,
    Grid,
    Heading,
    TextField,
    Text,
    View,
    PhoneNumberField,
    RadioGroupField,
    Radio,
  } from "@aws-amplify/ui-react";
import { validateField } from "./utils";
import * as React from "react";
import { HashLink as Link } from 'react-router-hash-link';

export default function GuestRSVP(props) {
    const [expand, setExpand] = React.useState(false);
    const [plusone, setPlusOne] = React.useState("");
    const [dialCode, setDialCode] = React.useState("");
    const [errors, setErrors] = React.useState({});

    const { guest, setGuest } = props;
    const guestReady = () => {
        if (guest.attending === null) {
            return false;
        }
        if (guest.attending) {
            const justGuest = (guest.email !== "" && guest.email !== null)
            && (guest.welcomeparty !== null)
            && (guest.shuttle !== null)
            && (guest.welcomeparty !== null)
            && (guest.roomblock !== null)
            && (guest.firstname !== "" && guest.firstname !== null)
            && (guest.lastname !== "" && guest.lastname !== null);
            if (guest.plusoneallowed) {
                if (guest.plusone) {
                    return justGuest && (guest.plusone.firstname !== "") && 
                    (guest.plusone.firstname !== undefined) &&
                    (guest.plusone.lastname !== "") && (guest.plusone.lastname !== undefined);
                }
            }
            return justGuest;
        } else {
            // not attending, no validate
            return true;
        }
    }

    const shouldBeChecked = (guest, diet) => {
        if (guest.plusone && guest.plusone[diet] !== undefined) {
            return guest.plusone[diet];
        } else {
            return false;
        }
    }

    const validations = {
        email: [{ type: "Email" }],
        phonenumber: [{ type: "Phone" }],
      };

    const runValidationTasks = async (
        fieldName,
        currentValue,
        getDisplayValue
      ) => {
        const value =
          currentValue && getDisplayValue
            ? getDisplayValue(currentValue)
            : currentValue;
        let validationResponse = validateField(value, validations[fieldName]);
        setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
        return validationResponse;
      };

    const toYesNo = (boolVal) => {
        if (boolVal === null) {
            return "";
        }
        if (boolVal) {
            return "yes";
        } else {
            return "no";
        }
    };
    const toYesNoPlusOne = (plusone) => {
        if (plusone) {
            return "yes";
        } else {
            return "no";
        }
    }
    const toBool = (yesNo) => {
        return yesNo === "yes";
    }
    if (expand) {
        return (
        <Card variation="outlined" id={guest.id} width={{medium: "30%", base: "95%"}}>
            <Text fontSize="1.5rem">RSVP for {guest.firstname} {guest.lastname}</Text>
            <RadioGroupField
                style={{marginTop: "1em"}}
                legend={<>Are you attending our wedding on September 7th? <Text as="span" fontSize="small" color="red">
                *
              </Text></>}
                name="attending"
                isRequired={true}
                value={toYesNo(guest.attending)}
                onChange={(e) => {
                    const { value } = e.target;
                    if (value === "yes") {
                        setGuest({
                        ...guest,
                        attending: toBool(e.target.value)
                        })
                    } else {
                        setGuest({
                            ...guest,
                            attending: toBool(e.target.value),
                            welcomeparty: null,
                            shuttle: null,
                            roomblock: null,
                            email: null,
                            dialcode: null,
                            phonenumber: null,
                            vegan: null,
                            vegetarian: null,
                            glutenfree: null,
                            nutfree: null,
                            otherallergy: null,
                            plusone: null,
                        })
                    }
                }}
                errorMessage={errors.attending?.errorMessage}
                hasError={errors.attending?.hasError}
            >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
            </RadioGroupField>
            {guest.attending && <>
                <RadioGroupField
                    style={{marginTop: "1em"}}
                    legend={<>Are you attending our welcome party on September 6th? <Text as="span" fontSize="small" color="red">
                    *
                  </Text></>}
                    descriptiveText={<><Link target="_blank" to="/details#welcomeparty" className="address">See here for more details.</Link> Don't forget to wear white!</>}
                    name="welcomeparty"
                    isRequired={true}
                    value={toYesNo(guest.welcomeparty)}
                    onChange={(e) => {
                        setGuest({
                        ...guest,
                        welcomeparty: toBool(e.target.value)
                    })}}
                    errorMessage={errors.welcomeparty?.errorMessage}
                    hasError={errors.welcomeparty?.hasError}
                >
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </RadioGroupField>
                <RadioGroupField
                    style={{marginTop: "1em"}}
                    legend={<>Are you planning to stay in our room block at the Hyatt Regency Westlake or the Marriott Residence Inn? <Text as="span" fontSize="small" color="red">
                    *
                  </Text></>}
                    descriptiveText={<>
                    This is to help us gauge interest only. Hotel reservations must be made separately. Availability is on a first come, first serve basis. <Link to="/details#accommodations" target="_blank" className="address">See here for more details.</Link></>
                    }
                    name="roomblock"
                    isRequired={true}
                    value={toYesNo(guest.roomblock)}
                    onChange={(e) => {
                        setGuest({
                        ...guest,
                        roomblock: toBool(e.target.value)
                    })}}
                    errorMessage={errors.roomblock?.errorMessage}
                    hasError={errors.roomblock?.hasError}
                >
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </RadioGroupField>
                <RadioGroupField
                    style={{marginTop: "1em"}}
                    legend={<>Are you planning to take the shuttle to the wedding from the Hyatt Regency Westlake or the Marriott Residence Inn? <Text as="span" fontSize="small" color="red">
                    *
                  </Text></>}
                    descriptiveText="You can take the shuttle even if you're not staying at the Hyatt or Marriott."
                    name="shuttle"
                    isRequired={true}
                    value={toYesNo(guest.shuttle)}
                    onChange={(e) => {
                        setGuest({
                        ...guest,
                        shuttle: toBool(e.target.value)
                    })}}
                    errorMessage={errors.shuttle?.errorMessage}
                    hasError={errors.shuttle?.hasError}
                >
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </RadioGroupField>
                <Text fontSize="1.2rem" style={{marginTop: "1em"}}>Your Preferred Name <Text as="span" fontSize="small" color="red" fontWeight="var(--amplify-components-fieldset-legend-font-weight)">
                    *
                  </Text></Text>
                <Text variation="secondary" fontSize="0.85rem" fontStyle="italic">This is how your name will appear at your place setting.</Text>
                <TextField
                label="First Name"
                value={guest.firstname}
                required={true}
                onChange={(e) => {
                    let newVal = e.target.value;
                    setGuest({
                    ...guest,
                    firstname: newVal,
                    });
                }}
                errorMessage={errors.firstname?.errorMessage}
                hasError={errors.firstname?.hasError}
                ></TextField>
                <TextField
                label="Last Name"
                value={guest.lastname}
                required={true}
                onChange={(e) => {
                    let newVal = e.target.value;
                    setGuest({
                    ...guest,
                    lastname: newVal,
                    });
                }}
                errorMessage={errors.lastname?.errorMessage}
                hasError={errors.lastname?.hasError}
                ></TextField>
                <br/>
                <Text fontSize="1.2rem" style={{marginBottom: "0.5em"}}>Contact Information</Text>
                <TextField
                label={<>Email Address <Text as="span" fontSize="small" color="red" fontWeight="var(--amplify-components-fieldset-legend-font-weight)">
                *
              </Text></>}
                isRequired={false}
                isReadOnly={false}
                style={{marginBottom: "0.5em"}}
                value={guest.email || ""}
                onChange={(e) => {
                    if (errors.email?.hasError) {
                        runValidationTasks("email", e.target.value);
                    }
                    setGuest({
                        ...guest,
                        email: e.target.value
                    })
                }}
                errorMessage={errors.email?.errorMessage}
                hasError={errors.email?.hasError}
                ></TextField>
                <PhoneNumberField
                defaultDialCode={guest.dialcode || "+1"}
                label="Phone Number"
                descriptiveText="Optional. Add a phone number to be added to our WhatsApp group for announcements and communicating with other guests."
                isRequired={false}
                isReadOnly={false}
                type="tel"
                size="small"
                value={guest.phonenumber}
                onDialCodeChange={(e) => {
                    setGuest({
                        ...guest,
                        dialcode: e.target.value,
                    });
                }}
                onChange={(e) => {
                    let { value } = e.target;
                    if (errors.phonenumber?.hasError) {
                        runValidationTasks("phonenumber", value);
                    }
                    setGuest({
                    ...guest,
                    dialcode: guest.dialcode || "+1",
                    phonenumber: value,
                    });
                }}
                errorMessage={errors.phonenumber?.errorMessage}
                hasError={errors.phonenumber?.hasError}
                ></PhoneNumberField>
                <Text fontSize="1.2rem" style={{marginTop: "1em", marginBottom: "0.5em"}}>Dietary Restrictions</Text>
                <CheckboxField
                label="Vegan"
                value="yes"
                checked={guest.vegan}
                onChange={(e) => {
                    setGuest({
                    ...guest,
                    vegan: !guest.vegan
                })}}
                />
                <CheckboxField
                label="Vegetarian"
                value="yes"
                checked={guest.vegetarian}
                onChange={(e) => {
                    setGuest({
                    ...guest,
                    vegetarian: !guest.vegetarian
                })}}
                />
                <CheckboxField
                label="Gluten-Free"
                value="yes"
                checked={guest.glutenfree}
                onChange={(e) => {
                    setGuest({
                    ...guest,
                    glutenfree: !guest.glutenfree
                })}}
                />
                <CheckboxField
                label="Nut-Free"
                value="yes"
                checked={guest.nutfree}
                onChange={(e) => {
                    setGuest({
                    ...guest,
                    nutfree: !guest.nutfree
                })}}
                />
                <TextField
                label="Other"
                placeholder="Add any other allergies or intolerances."
                value={guest.otherallergy || ""}
                onChange={(e) => {
                    setGuest({
                    ...guest,
                    otherallergy: e.target.value
                })}}
                />
                {guest.plusoneallowed &&
                <RadioGroupField
                    style={{marginTop: "1em"}}
                    legend={<>Are you bringing a plus one? <Text as="span" fontSize="small" color="red" fontWeight="var(--amplify-components-fieldset-legend-font-weight)">
                    *
                  </Text></>}
                    name="plusone"
                    isRequired={true}
                    defaultValue={toYesNoPlusOne(guest.plusone)}
                    onChange={(e) => {
                        const { value } = e.target;
                        if (value === "yes") {
                            setGuest({
                                ...guest,
                                plusone: {"temp": true}
                            })
                        } else {
                            const newGuest = { ...guest };

                            // Delete the specified key
                            delete newGuest["plusone"];
                    
                            // Update the state with the new object
                            setGuest(newGuest);
                        }
                    }
                    }
                    >
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                </RadioGroupField>
                }
                {guest.plusone && <>
                    <Text fontSize="1.2rem" style={{marginTop: "1em", marginBottom: "0.5em"}}>Your Plus One's Preferred Name <Text as="span" fontSize="small" color="red" fontWeight="var(--amplify-components-fieldset-legend-font-weight)">
                    *
                  </Text></Text>
                    <TextField
                        label="First Name"
                        value={guest.plusone ? (guest.plusone.firstname || "") : ""}
                        isRequired={true}
                        onChange={(e) => {
                        const { value } = e.target;
                        const { plusone } = guest;
                        setGuest({
                            ...guest,
                            plusone: {
                                ...plusone,
                                firstname: value
                            }
                        });
                        }}
                    ></TextField>
                    <TextField
                        label="Last Name"
                        value={guest.plusone ? (guest.plusone.lastname || "") : ""}
                        isRequired={true}
                        onChange={(e) => {
                        const { value } = e.target;
                        const { plusone } = guest;
                        setGuest({
                            ...guest,
                            plusone: {
                                ...plusone,
                                lastname: value
                            }
                        });
                        }}
                    ></TextField>
                    <Text fontSize="1.2rem" style={{marginTop: "1em", marginBottom: "0.5em"}}>Your Plus One's Dietary Restrictions</Text>
                    <CheckboxField
                    label="Vegan"
                    checked={shouldBeChecked(guest, "vegan")}
                    value="yes"
                    onChange={(e) => {
                        const { value } = e.target;
                        const { plusone } = guest;
                        setGuest({
                            ...guest,
                            plusone: {
                                ...plusone,
                                vegan: !guest.plusone.vegan
                            }
                        });
                        }}
                    />
                    <CheckboxField
                    label="Vegetarian"
                    checked={shouldBeChecked(guest, "vegetarian")}
                    value="vegetarian"
                    onChange={(e) => {
                        const { value } = e.target;
                        const { plusone } = guest;
                        setGuest({
                            ...guest,
                            plusone: {
                                ...plusone,
                                vegetarian: !guest.plusone.vegetarian
                            }
                        });
                        }}
                    />
                    <CheckboxField
                    label="Gluten-Free"
                    value="glutenfree"
                    checked={shouldBeChecked(guest, "glutenfree")}
                    onChange={(e) => {
                        const { value } = e.target;
                        const { plusone } = guest;
                        setGuest({
                            ...guest,
                            plusone: {
                                ...plusone,
                                glutenfree: !guest.plusone.glutenfree
                            }
                        });
                        }}
                    />
                    <CheckboxField
                    label="Nut-Free"
                    value="nutfree"
                    checked={shouldBeChecked(guest, "nutfree")}
                    onChange={(e) => {
                        const { value } = e.target;
                        const { plusone } = guest;
                        setGuest({
                            ...guest,
                            plusone: {
                                ...plusone,
                                nutfree: !guest.plusone.nutfree
                            }
                        });
                        }}
                    />
                    <TextField
                    label="Other"
                    placeholder="Add any other allergies or intolerances."
                    value={guest.plusone ? (guest.plusone.otherallergy || "") : ""}
                    onChange={(e) => {
                    const { value } = e.target;
                    const { plusone } = guest;
                    setGuest({
                        ...guest,
                        plusone: {
                            ...plusone,
                            otherallergy: value
                        }
                    });
                    }}
                    ></TextField>
                    </>
                }
            </>
            }
            <Flex justifyContent="center">
                <Button
                onClick={async() => {
                    const validationResponses = await Promise.all(
                        Object.keys(validations).reduce((promises, fieldName) => {
                          promises.push(
                            runValidationTasks(fieldName, guest[fieldName])
                          );
                          return promises;
                        }, [])
                      );
                      if (validationResponses.some((r) => r.hasError)) {
                        return;
                      }
                    setGuest({
                        ...guest,
                        rsvped: true,
                    })
                    setExpand(false)
                }
                }
                isDisabled={!guestReady()}
                style={{ maxHeight: "40px", "marginRight": "1em", "marginTop": "1em" }}
                >
                Save
                </Button>
            </Flex>
        </Card>
        );
    } else {
        return (
        <Card variation="outlined" id={guest.id} width={{medium: "30%", base: "95%"}}>
            <Flex alignItems="center" justifyContent="center">
            <Badge variation={guest.rsvped ? "success" : "default"}>{guest.rsvped ? "✓" : ""}</Badge>
            <Heading level={3} fontSize={{ medium: "2vw", base: "4vw" }}>{guest.firstname} {guest.lastname}</Heading> 
            <Button onClick={() => setExpand(true)} style={{textAlign: "center"}}>
            Complete RSVP
            </Button>
        </Flex>
        </Card>
        )
    }
}
