import { Card, Heading, Image, View } from "@aws-amplify/ui-react";
import React, { useEffect } from 'react';
const gifUrl = 'https://i.kym-cdn.com/photos/images/original/002/052/346/de7.gif';

const Popup = ({ onClose }) => (
  <div className="popup-roommates" onClick={onClose}>
    <div className="popup-roommates-content">
    <img src={gifUrl} alt="And they were roommates!" />
    </div>
  </div>
);

export default function Story({ background }) {
  useEffect(() => {
    document.body.style.background = background;

    // Clean up the effect when the component is unmounted
    return () => {
      document.body.style.background = 'white';
    };
  }, [background]);

  const [showRoommate, setShowRoommate] = React.useState(false);

  const handleRoommate = () => {
    setShowRoommate(!showRoommate);
  };

  const handleRoommateClose = () => {
    setShowRoommate(false);
  };

  return (
    <>
      <Card
        column="auto / span 6"
        row="2"
        justifyContent="center"
        display="flex"
        alignItems="center"
        paddingBottom="0"
      >
        <View style={{textAlign: "center" }}>
          <Heading level={2} fontSize={{medium: "4.5vw", base: "6vw"}}>Our Story</Heading>
        </View>
      </Card>
      <Card className="mobile-menu" row="3" column="2 / 6" >
        <p>
          Fate brought Diana and Nicki together <span onClick={handleRoommate} style={{cursor: "pointer"}}>as floormates</span> in the same dorm
          their freshman year at UC Berkeley.
        </p>
        {showRoommate && <Popup onClose={handleRoommateClose} />}
        <Image src="./college.jpg"/>
        <p>
          Fast forward to their senior year, they coincidentally had two Computer Science classes together.
          They reconnected one night after an Algorithms midterm, and instantly knew their feelings went beyond
          friendship; they were meant to be all along.
        </p>
        <Image src="./pofa.jpg"/>
        <p>
          After graduating from UC Berkeley in 2018, they both moved to San
          Francisco for work. Over the next few years, they navigated the
          complexities of surviving post-grad life, coming out to family and friends, and
          sheltering in place during the pandemic. It was during their
          quarantine days that they solidified plans for their relationship
          long-term. They moved into their own apartment near Fort Mason in San
          Francisco, and had their lives forever changed through the adoption
          of Kimmy, their now 3-year-old dog. Recently, they purchased their first home in Walnut Creek where
          Kimmy can fulfill her dreams as a full-time squirrel watcher.
        </p>
        <Image src="./kimmy.jpg"/>
        <p>
          On May 11, 2022, Diana and Nicki got engaged during a trip to Kauai with their best friends. Now, they are
          entering into their next chapter of lives planning their special day with the love and support of all of you!
        </p>
        <Image src="./engagement.jpg"/>
      </Card>
      <Card row="3" column={{ medium: "2 / 4", base:"1 / span 6"}} className="webMenu">
        <p>
          Fate brought Diana and Nicki together <span onClick={handleRoommate} style={{cursor: "pointer"}}>as floormates</span> in the same dorm
          their freshman year at UC Berkeley.
        </p>
        {showRoommate && <Popup onClose={handleRoommateClose} />}
      </Card>
      <Card row="3" column={{medium: "4 / 6", base: "1 / span 6"}} className="webMenu">
        <Image src="./college.jpg"/>
      </Card>
      <Card row="4" column={{medium: "2 / 4", base: "1 / span 6"}} className="webMenu">
        <Image src="./pofa.jpg"/>
      </Card>
      <Card row="4" column={{medium: "4 / 6", base: "1 / span 6"}} className="webMenu">
        <p>
          Fast forward to their senior year, they coincidentally had two Computer Science classes together.
          They reconnected one night after an Algorithms midterm, and instantly knew their feelings went beyond
          friendship; they were meant to be all along.
        </p>
      </Card>
      <Card row="5" column={{medium: "2 / 4", base: "1 / span 6"}} className="webMenu">
        <p>
          After graduating from UC Berkeley in 2018, they both moved to San
          Francisco for work. Over the next few years, they navigated the
          complexities of surviving post-grad life, coming out to family and friends, and
          sheltering in place during the pandemic. It was during their
          quarantine days that they solidified plans for their relationship
          long-term. They moved into their own apartment near Fort Mason in San
          Francisco, and had their lives forever changed through the adoption
          of Kimmy, their now 3-year-old dog. Recently, they purchased their first home in Walnut Creek where
          Kimmy can fulfill her dreams as a full-time squirrel watcher.
        </p>
      </Card>
      <Card row="5" column={{medium: "4 / 6", base: "1 / span 6"}} className="webMenu">
        <Image src="./kimmy.jpg"/>
      </Card>
      <Card row="6" column="2/4" className="webMenu">
        <Image src="./engagement.jpg"/>
      </Card>
      <Card row="6" column={{medium: "4 / 6", base: "1 / span 6"}} className="webMenu">
        <p>
          On May 11, 2022, Diana and Nicki got engaged during a trip to Kauai with their best friends. Now, they are
          entering into their next chapter of lives planning their special day with the love and support of all of you!
        </p>
      </Card>
    </>
  );
}