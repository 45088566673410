// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config =  {
    API: {
        GraphQL: {
          endpoint: 'https://tqiu6tvtt5gsfjdwgdprnwwabu.appsync-api.us-east-1.amazonaws.com/graphql',
          region: 'us-east-1',
          defaultAuthMode: 'apiKey',
          apiKey: 'da2-p5uiau2d7zcl7gaqv5q33gebxq'
        }
    }
};

export default config;
