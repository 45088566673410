import { Card, Link, Heading, Image, View } from "@aws-amplify/ui-react";
import { fairyDustCursor } from "cursor-effects";
import cursor from "../wand.png";
import React, { useEffect } from "react";

const Popup = ({ onClose }) => (
  <div className="popup" onClick={onClose}>
    <div className="popup-video">
      <iframe
        title="Rick Astley - Never Gonna Give You Up"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1"
        allow="autoplay; encrypted-media"
      ></iframe>
      <p>
        Come on, that would be too easy!! Keep hunting though. <br /> You can
        make this stop by clicking anywhere in this box.
      </p>
    </div>
  </div>
);

export default function FAQ({ background }) {
  useEffect(() => {
    document.body.style.background = background;

    // Clean up the effect when the component is unmounted
    return () => {
      document.body.style.background = "white";
    };
  }, [background]);
  const [partyMode, setPartyMode] = React.useState(false);
  const [fairyDust, setFairyDust] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);

  const handleRickRoll = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };
  const handlePartyMode = (e) => {
    const newVal = !partyMode;
    if (newVal) {
      console.log("Starting party mode!!!!");
      setFairyDust(
        new fairyDustCursor({ colors: ["#FFE033", "#F7E57F", "#F9D064"] })
      );
      document.body.style.cursor = `url(${cursor}), auto`;
    } else {
      fairyDust.destroy();
      document.body.style.cursor = "auto";
    }
    setPartyMode(newVal);
  };

  return (
    <>
      {showPopup && <Popup onClose={handleClose} />}
      <Card column="auto / span 6" row="2" paddingBottom="8px">
        <View style={{ textAlign: "center" }}>
          <Heading level={2} fontSize={{ medium: "4vw", base: "6vw" }}>
            Frequently Asked Questions
          </Heading>
        </View>
      </Card>
      <Card
        row={{ medium: "3 / span 6", base: "3" }}
        column={{ medium: "2 / 4", base: "2 / span 4" }}
      >
        <Image objectFit="initial" src="./faq_small.jpg" />
      </Card>
      <Card
        row={{ medium: "3 / span 6", base: "4" }}
        column={{ medium: "4 / 6", base: "1 / span 6" }}
      >
        <ul>
          <li>
            When and where is the wedding?
            <p>
              Our wedding is on September 7, 2024 at{" "}
              <Link isExternal="true" href="https://www.mgardens.com/">
                Maravilla Gardens
              </Link>{" "}
              in Camarillo in Southern California.
            </p>
          </li>
          <li>
            Why Southern California?
            <p>
              We chose a venue here so that we could practically guarantee clear
              weather. Diana's vision is that we{" "}
              <span className="partymode" onClick={handlePartyMode}>
                dance the night away under the stars!
              </span>{" "}
            </p>
          </li>
          <li>
            What is the dress code for your wedding?
            <p>
              Formal. We ask that guests wear a tuxedo or suit, mid-length or
              floor-length dress, or pantsuit. Please avoid wearing white.
            </p>
          </li>
          <li>
            Am I allowed to bring a plus one?
            <p>Every unattached guest is allowed a plus one.</p>
          </li>
          <li>
            Is there a gift registry?
            <p>
              No, and we don't expect any gifts from our guests. Your presence
              at our special day is the best gift we could ask for!
            </p>
          </li>
          <li>
            I'm experiencing an issue with the website...?
            <p style={{ paddingBottom: "5em" }}>
              Sorry, Nicki built this website herself so it might be buggy (that
              also means there might be some{" "}
              <a href="#" onClick={handleRickRoll}>
                hidden easter eggs 😉
              </a>
              )
              <br />
              If you run into an issue, let her know and she'll fix it!
              <br /> <br />
              Special shoutout to Erin Delahunty for transforming this from a
              bunch of HTML into a properly designed website.
            </p>
          </li>
        </ul>
      </Card>
    </>
  );
}
